import biz from './biz.js'
import utils from '@/base/utils'
import OrgFunc from "@/eap/user/org.js";
export default {
    biz,
    FormFunc: biz,
    DateFunc: utils.date,
    NumberFunc: utils.number,
    StringFunc: utils.string,
    BomFunc: utils.bom,
    OrgFunc: OrgFunc
}