
import axios from '@/eap/axios'
let service = axios;
//export default service

export default function (cfg) {
  try {
    if (((window && window.isElectron) || (top && top.window && top.window.isElectron))) {
      var urlsCallback
      if (window.isElectron)
        urlsCallback = window.electronCfg.urlCallback
      if (top.window.isElectron)
        urlsCallback = top.window.electronCfg.urlCallback
      for (var key in urlsCallback) {
        if (cfg.url.indexOf(key) > -1)
          return urlsCallback[key](cfg);
      }
    }
  } catch (e) {

  }
  if (typeof (cfg.timeout) != "undefined") {
    cfg.isCustomTimeout = true;
  }
  return axios(cfg);
} 