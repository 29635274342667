export default {
    "pageIndex": "pageNum",
    "pageSize": "pageSize",
    "body": "__body",
    "funcpath":"__funcpath",
    "returnTotal":"__returnCount",
    "zcQuery":"__zcQuery",
    "slaveEntities":"__slaveEntities",
    "modelFilePath":"modelFilePath",
    "slaveExport":"__slaveExport",
    "sheetStyle":"__sheetStyle",
    "sheetDatas":"__sheetDatas",
    "viewItemId":"viewItemId"
}