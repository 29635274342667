import types from "./types"
import keys from "./keys"
import _has from "lodash/has";

import isDate from 'lodash/isDate'
import { date } from '../../../utils'

const defOperate = "="
let WhereCondition = class WhereCondition {

    constructor(config) {
        config = config || {};
        this.className = 'WhereCondition'
        this.enabled = null,// 新添加的
            this.sign = null,// 操作符
            this.name = null,// 属性
            this.dataType = null,// 数据类型
            this.tablefilter = null,// tablefilter
            this.value = null,// 值
            this.enabled = true,
            this.setValue(config.name, config.value, config.dataType,
                config.sign, config.tablefilter);
    }
    setValue(property, value, dataType, operator, tablefilter) {
        this.sign = operator || "eq";
        this.name = property || "";
        this.dataType = dataType || types.string;
        this.value = value;
        this.enabled = true;
        this.tablefilter = tablefilter == undefined ? false :
            tablefilter;
    }
    getValue() {
        return {
            sign: this.sign,
            name: this.name,
            tablefilter: this.tablefilter,
            dataType: this.dataType,
            value: this.value,
            enabled: true
        };
    }

};


let Where = class Where {
    /**
       * showdoc
       * @title 查询条件
       * @className Where
       * @method Where
       */
    constructor(config, params = []) {
        config = config || {};
        this.params = params
        this.className = 'Where'
        this.join = null
        this.items = null

        // _.extend(this, config);
        if (config.junction)
            config.join = config.junction;
        if (!config.join)
            config.join = "and";

        if (config.criterionData == null) {
            if (!config.join)
                config.join = "and";
            this.setWhereData(config.join, []);
        } else {
            var paramsDataType = typeof (config.criterionData);
            var objParamsData = {};
            switch (paramsDataType) {
                case "string":
                    objParamsData = JSON.parse(config.criterionData);//$.parseJSON(config.criterionData);
                    break;
                case "object":
                    objParamsData = config.criterionData;
                    break;
            }
            this.setCriterionData(objParamsData);
        }
    }

    setValue(join, items) {
        this.setWhereData(join, items);
    }

    setWhereData(join, items) {
        this.join = join || "and";
        this.items = items || [];
    }
    // 

    setCriterionData(criterionData) {
        var paramsDataType = typeof (criterionData);
        var objParamsData = {};
        switch (paramsDataType) {
            case "string":
                objParamsData = JSON.parse(criterionData);
                break;
            case "object":
                objParamsData = criterionData;
                break;
        }
        this.join = objParamsData.join || "and";
        this.items = [];
        this.recursiveSetCriteria(this, objParamsData.items);
    }
    recursiveSetCriteria(where, items) {
        var me = this;
        if (items == null || items.length == 0) {
            where.items = [];
            return;
        }
        var subLen = items.length;
        for (var i = 0; i < subLen; i++) {
            var subItem = items[i];
            if (subItem.join) {
                var whereItem = new Where({
                    join: subItem.join
                });
                this.recursiveSetCriteria(whereItem, subItem.items);
                where.addWhere(whereItem);
            } else {
                if (subItem.sign) {
                    var fieldName = subItem.name.replace("&[", "").replace("]",
                        "");
                    fieldName = fieldName.replace("this.get('", "").replace(
                        "')", "");
                    if (typeof subItem.value == "function") {
                        var tempVal = me.filterValue(subItem.value)
                        where.addCondition(fieldName,
                            tempVal, subItem.dataType, subItem.sign, false,
                            subItem.tablefilter);
                    }

                    else
                        where.addCondition(fieldName,
                            subItem.value, subItem.dataType, subItem.sign,
                            false, subItem.tablefilter);
                }

            }
        }
    }
    // 过滤表达式
    filterValue(valueExpress) {
        var me = this, val;
        val = valueExpress.call(this, ...this.params);
        return val;
    }
    /**
       * showdoc
      * @catalog API/工具/Where条件
       * @title 添加where条件
       * @description 添加where条件
       * @method addWhere
       * @param where 必选 Where where条件
       * @return void
       * @number 60
       */
    addWhere(where) {
        // 
        if (where) {
            let isFind = false;
            for (let i = 0; i < this.items.length; i++) {
                let itemstr = JSON.stringify(this.items[i])
                if (itemstr == JSON.stringify(where.toJSON())) {
                    isFind = true;
                    break;
                }

            }
            if (!isFind)
                this.items.push(where);
        }
    }
    /**
       * showdoc
       * @title 添加字段条件
       * @description 添加字段条件
       * @method addCondition
       * @param property 必选 String 字段名
       * @param value 必选 String|Array 值
       * @param dataType 必选 String 值类型
       * @param operator 必选 String 操作符(eq,noteq,in,notin,gt,lt,isnull,isnotnull,gteq,lteq,like,likeLeft,likeRight)
       * @param isCover 可选 boolean  是否覆盖原来存在的值
       * @param tablefilter 可选 String tablefilter过滤
       * @return void
       */
    addCondition(property, value, dataType, operator, isCover, tablefilter) {
        if (value === null || value == undefined || value === "null") {
            operator = "isnull";
            value = "null";
        }
        if (operator === "isnull")
            value = "null";
        if(dataType && dataType=="dbString")
            value = value + ""
        //采用后端默认值
        if (!operator && window.HIVUI_SETTING)
            operator = window.HIVUI_SETTING.queryOperate || 'eq';
        if (!operator)
            operator = 'eq';

        let value1, operateArray = ["in", "notIn"];
        if (typeof value === "function") {
            value = value.call();
        }
        if (isDate(value)) {
            value = date.format(value, 'yyyy-MM-dd hh:mm:ss')
        }

        if (operateArray.indexOf(operator) > -1
            && value && (!(value instanceof Array))) {
            value1 = (value + "").split(",");
        } else {
            value1 = value;
        }

        if (isCover)
            for (var i = 0; i < this.items.length; i++) {
                if (property == this.items[i].name) {
                    this.items[i].value = value1;
                    this.items[i].tablefilter = tablefilter;
                    this.items[i].dataType = dataType || types.string;
                    this.items[i].sign = operator;
                    return;
                }
            }
        var condition = new WhereCondition({
            name: property,
            tablefilter: tablefilter,
            value: value1,
            sign: operator,
            dataType: dataType || types.string
        });
        this.items.push(condition);
    }

    add(property, value, dataType, isCover, tablefilter) {
        this.addEqual(property, value, dataType, isCover,
            tablefilter)
    }

    _addEqual(property, value, dataType, isCover,
        tablefilter) {
        this.addCondition(property, value, dataType, "eq", isCover, tablefilter);
    }

    _addArray(property, value, dataType, isCover,
        tablefilter) {
        if (!(value instanceof Array) && value.split) {
            value = value.split(",");
        }
        if (value.length != 0)
            this.addCondition(property, value, dataType, "in", isCover, tablefilter);
    }

    _addNotIn(property, value, dataType, isCover, tablefilter) {
        if (!(value instanceof Array) && value.split) {
            value = value.split(",");
        }
        if (value.length != 0)
            this.addCondition(property, value, dataType, "notIn", isCover, tablefilter);
    }

    _addIsNull(property, value, dataType, isCover, tablefilter) {
        this.addCondition(property, "", dataType, "isnull", isCover, tablefilter);

    }

    _addIsNotNull(property, value, dataType, isCover, tablefilter) {
        this.addCondition(property, "", dataType, "isnotnull", isCover, tablefilter);
    }

    _addNotEqual(property, value, dataType, isCover, tablefilter) {
        this.addCondition(property, value, dataType, "noteq", isCover,
            tablefilter);
    }

    _addGreaterThan(property, value, dataType, isCover, tablefilter) {
        this.addCondition(property, value, dataType, "gt", isCover, tablefilter);
    }

    _addLessThan(property, value, dataType, isCover, tablefilter) {
        this.addCondition(property, value, dataType, "lt", isCover, tablefilter);
    }

    _addGreaterThanAndEqual(property, value, dataType, isCover, tablefilter) {
        this.addCondition(property, value, dataType, "gteq", isCover, tablefilter);
    }

    _addLike(property, value, dataType, isCover, tablefilter) {
        this.addCondition(property, value, dataType, "like", isCover, tablefilter);
    }

    _addLessThanAndEqual(property, value, dataType, isCover, tablefilter) {
        this.addCondition(property, value, dataType, "lteq", isCover,
            tablefilter);
    }

    _addLeftLike(property, value, dataType, isCover,
        tablefilter) {
        this.addCondition(property, value, dataType, "likeLeft",
            isCover, tablefilter);
    }

    _addRightLike(property, value, dataType, isCover,
        tablefilter) {
        this.addCondition(property, value, dataType, "likeRight",
            isCover, tablefilter);
    }
    /**
         * showdoc
         * @title 添加等于（=）操作符字段条件
         * @description 添加默认操作符字段条件，操作符取决于全局设置
         * @method eq
         * @param property 必选 String 字段名
         * @param value 必选 String|Array 值
         * @param dataType 必选 String 值类型
         * @param isCover 可选 boolean  是否覆盖原来存在的值
         * @param tablefilter 可选 String tablefilter过滤
         * @return void
         */
    eq(property, value, dataType, isCover,
        tablefilter) {

        this._addEqual(property, value, dataType, isCover,
            tablefilter)
    }
    /**
          * showdoc
          * @title 添加默认操作符字段条件
          * @description 添加默认操作符字段条件，操作符取决于全局设置
          * @method def
          * @param property 必选 String 字段名
          * @param value 必选 String|Array 值
          * @param dataType 必选 String 值类型
          * @param isCover 可选 boolean  是否覆盖原来存在的值
          * @param tablefilter 可选 String tablefilter过滤
          * @return void
          */
    def(property, value, dataType, isCover, tablefilter) {
        this.addCondition(property, value, dataType, null, isCover, tablefilter)
    }
    /**
         * showdoc
         * @title 添加in操作符字段条件
         * @description 添加默认操作符字段条件，操作符取决于全局设置
         * @method in
         * @param property 必选 String 字段名
         * @param value 必选 String|Array 值
         * @param dataType 必选 String 值类型
         * @param isCover 可选 boolean  是否覆盖原来存在的值
         * @param tablefilter 可选 String tablefilter过滤
         * @return void
         */
    in(property, value, dataType, isCover,
        tablefilter) {
        if (!(value instanceof Array) && value.split) {
            value = value.split(",");
        }
        if (value.length != 0)
            this.addCondition(property, value, dataType, "in", isCover
                , tablefilter);
    }

    /**
            * showdoc
            * @title 添加notin操作符字段条件
            * @description 添加默认操作符字段条件，操作符取决于全局设置
            * @method notIn
            * @param property 必选 String 字段名
            * @param value 必选 String|Array 值
            * @param dataType 必选 String 值类型
            * @param isCover 可选 boolean  是否覆盖原来存在的值
            * @param tablefilter 可选 String tablefilter过滤
            * @return void
            */
    notIn(property, value, dataType, isCover,
        tablefilter) {
        if (!(value instanceof Array) && value.split) {
            value = value.split(",");
        }
        if (value.length != 0)
            this.addCondition(property, value, dataType, "notIn",
                isCover, tablefilter);
    }

    /**
         * showdoc
         * @title 添加inRange 内操作符字段条件
         * @description 添加默认操作符字段条件，操作符取决于全局设置
         * @method inRange
         * @param property 必选 String 字段名
         * @param value 必选 String|Array 值
         * @param dataType 必选 String 值类型
         * @param isCover 可选 boolean  是否覆盖原来存在的值
         * @param tablefilter 可选 String tablefilter过滤
         * @return void
         */
    inRange(property, value, dataType, isCover,
        tablefilter) {
        this.addCondition(property, value, dataType, "inRange", isCover
            , tablefilter);
    }
    /**
         * showdoc
         * @title 添加outRange 外操作符字段条件
         * @description 添加默认操作符字段条件，操作符取决于全局设置
         * @method outRange
         * @param property 必选 String 字段名
         * @param value 必选 String|Array 值
         * @param dataType 必选 String 值类型
         * @param isCover 可选 boolean  是否覆盖原来存在的值
         * @param tablefilter 可选 String tablefilter过滤
         * @return void
         */
    outRange(property, value, dataType, isCover,
        tablefilter) {
        this.addCondition(property, value, dataType, "outRange", isCover,
            tablefilter);
    }
    /**
         * showdoc
         * @title 添加between操作符字段条件
         * @description 添加默认操作符字段条件，操作符取决于全局设置
         * @method between
         * @param property 必选 String 字段名
         * @param value 必选 String|Array 值
         * @param dataType 必选 String 值类型
         * @param isCover 可选 boolean  是否覆盖原来存在的值
         * @param tablefilter 可选 String tablefilter过滤
         * @return void
         */
    between(property, value, dataType, isCover,
        tablefilter) {
        this.addCondition(property, value, dataType, "between", isCover,
            tablefilter);
    }
    /**
         * showdoc
         * @title 添加noteq不等于操作符字段条件
         * @description 添加默认操作符字段条件，操作符取决于全局设置
         * @method noteq
         * @param property 必选 String 字段名
         * @param value 必选 String|Array 值
         * @param dataType 必选 String 值类型
         * @param isCover 可选 boolean  是否覆盖原来存在的值
         * @param tablefilter 可选 String tablefilter过滤
         * @return void
         */
    noteq(property, value, dataType, isCover,
        tablefilter) {
        this._addNotEqual(property, value, dataType, isCover,
            tablefilter);
    }
    /**
         * showdoc
         * @title 添加大于操作符字段条件
         * @description 添加默认操作符字段条件，操作符取决于全局设置
         * @method gt
         * @param property 必选 String 字段名
         * @param value 必选 String|Array 值
         * @param dataType 必选 String 值类型
         * @param isCover 可选 boolean  是否覆盖原来存在的值
         * @param tablefilter 可选 String tablefilter过滤
         * @return void
         */
    gt(property, value, dataType, isCover, tablefilter) {
        this._addGreaterThan(property, value, dataType, isCover,
            tablefilter);

    }
    /**
         * showdoc
         * @title 添加大于等于操作符字段条件
         * @description 添加默认操作符字段条件，操作符取决于全局设置
         * @method gteq
         * @param property 必选 String 字段名
         * @param value 必选 String|Array 值
         * @param dataType 必选 String 值类型
         * @param isCover 可选 boolean  是否覆盖原来存在的值
         * @param tablefilter 可选 String tablefilter过滤
         * @return void
         */
    gteq(property, value, dataType, isCover,
        tablefilter) {
        this._addGreaterThanAndEqual(property, value, dataType, isCover,
            tablefilter);
    }
    /**
         * showdoc
         * @title 添加小于操作符字段条件
         * @description 添加默认操作符字段条件，操作符取决于全局设置
         * @method lt
         * @param property 必选 String 字段名
         * @param value 必选 String|Array 值
         * @param dataType 必选 String 值类型
         * @param isCover 可选 boolean  是否覆盖原来存在的值
         * @param tablefilter 可选 String tablefilter过滤
         * @return void
         */
    lt(property, value, dataType, isCover, tablefilter) {
        this._addLessThan(property, value, dataType, isCover,
            tablefilter)
    }
    /**
         * showdoc
         * @title 添加小于等于操作符字段条件
         * @description 添加默认操作符字段条件，操作符取决于全局设置
         * @method lteq
         * @param property 必选 String 字段名
         * @param value 必选 String|Array 值
         * @param dataType 必选 String 值类型
         * @param isCover 可选 boolean  是否覆盖原来存在的值
         * @param tablefilter 可选 String tablefilter过滤
         * @return void
         */
    lteq(property, value, dataType, isCover,
        tablefilter) {
        this._addLessThanAndEqual(property, value, dataType, isCover,
            tablefilter)
    }
    /**
         * showdoc
         * @title 添加like操作符字段条件
         * @description 添加默认操作符字段条件，操作符取决于全局设置
         * @method like
         * @param property 必选 String 字段名
         * @param value 必选 String|Array 值
         * @param dataType 必选 String 值类型
         * @param isCover 可选 boolean  是否覆盖原来存在的值
         * @param tablefilter 可选 String tablefilter过滤
         * @return void
         */
    like(property, value, dataType, vtype, isCover, tablefilter) {
        this._addLike(property, value, dataType, isCover,
            tablefilter)
    }
    /**
         * showdoc
         * @title 添加左like操作符字段条件
         * @description 添加默认操作符字段条件，操作符取决于全局设置
         * @method leftLike
         * @param property 必选 String 字段名
         * @param value 必选 String|Array 值
         * @param dataType 必选 String 值类型
         * @param isCover 可选 boolean  是否覆盖原来存在的值
         * @param tablefilter 可选 String tablefilter过滤
         * @return void
         */
    leftLike(property, value, dataType, isCover,
        tablefilter) {
        this._addLeftLike(property, value, dataType, isCover,
            tablefilter);

    }
    /**
         * showdoc
         * @title 添加右like操作符字段条件
         * @description 添加默认操作符字段条件，操作符取决于全局设置
         * @method rightLike
         * @param property 必选 String 字段名
         * @param value 必选 String|Array 值
         * @param dataType 必选 String 值类型
         * @param isCover 可选 boolean  是否覆盖原来存在的值
         * @param tablefilter 可选 String tablefilter过滤
         * @return void
         */
    rightLike(property, value, dataType, isCover,
        tablefilter) {
        this._addRightLike(property, value, dataType, isCover,
            tablefilter);
    }

    getItemByIndex(i) {
        return this.items[i];
    }
    deleteItemByIndex(i) {
        this.items.splice(i, 1);
    }

    getData() {
        var len = this.items.length;
        if (len == 0) {
            return null;
        }
        var whereData = {
            join: this.join,
            items: []
        };
        for (var i = 0; i < len; i++) {
            var item = this.items[i];
            this.recursiveWhereData(whereData, item);
        }
        return whereData;
    }

    recursiveWhereData(whereData, item) {
        if (item.items) {
            var subLen = item.items.length;
            if (subLen > 0) {
                var subWhereData = {
                    join: item.join,
                    items: []
                };
                for (var i = 0; i < subLen; i++) {
                    var subItem = item.items[i];
                    this.recursiveWhereData(subWhereData, subItem);
                }
                whereData.items.push(subWhereData);
            }
        } else {
            var itemCondition = item.getValue();
            whereData.items.push(itemCondition);
        }
    }
    /**
       * showdoc
      * @catalog API/工具/Where条件
       * @title 获取where条件JSON数据
       * @description 获取where条件JSON数据
       * @method toJSON()
       * @return json
       * @number 60
       */
    toJSON() {
        return this.getData();
    }
    /**
      * showdoc
     * @catalog API/工具/Where条件
      * @title 获取where条件字符串数据
      * @description 获取where条件字符串数据
      * @url toStr()
      * @method toStr()
      * @return String
      * @number 60
      */
    toStr() {
        return JSON.stringify(this.getData());
    }


    /**
      * showdoc
     * @catalog API/工具/Where条件
      * @title 根据字段ID返回该字段条件对象
      * @description 根据字段ID返回该字段条件对象
      * @param key 必选 String 字段名
      * @method getConditionByKey(key)
      * @return json
      * @number 60
      */
    getConditionByKey(key) {
        var len = this.items.length;
        for (var i = 0; i < len; i++) {
            var item = this.items[i];
            if (item.name && item.name == key) {
                return item;
            }
            else {
                if (!item.items || item.items.length == 0) return null;
                let _item = this._recursionWhere1(item.items, key);
                if (_item != null)
                    return _item;
            }
        }
        return null;
    }
    _recursionWhere1(items, key) {
        var i = 0, len = items.length;
        for (; i < len; i++) {
            let item = items[i]
            if (item.name && item.name == key)
                return item
            if (item.name)
                continue;
            if (!item.items || item.items.length == 0) return null;
            item.items.length > 0 && this._recursionWhere1(item.items, key);


        }
    }
    // 应用存储过程
    setCustomWhere(join) {
        var where = new Where({
            join: join || "and"
        });
        var i = 0, len = this.items.length;
        for (; i < len; i++) {
            if (this.items[i].name) {
                where.addCondition(this.items[i].name, this.items[i].value, this.items[i].dataType, this.items[i].sign, this.items[i].isCover, this.items[i].tablefilter);
            } else
                this._recursionWhere(where, this.items[i].items);
        }
        this.join = where.join;
        this.items = where.items;
    }
    // 递归where 所有
    _recursionWhere(where, items) {
        var i = 0, len = items.length;
        for (; i < len; i++) {
            if (items[i].name)
                where.addCondition(items[i].name, items[i].value, items[i].dataType, items[i].sign, items[i].isCover, items[i].tablefilter);
            else
                this._recursionWhere(where, items[i].items);
        }

    }

    isPaging() {
        var pageIndexKey = keys.pageIndex;
        var pageSizeKey = keys.pageSize;

        if (_has(this, pageIndexKey) && _has(this, pageSizeKey))
            return true;
        else
            return false;
    }

    setPage(pageIndex, pageSize) {
        var pageIndexKey = keys.pageIndex;
        var pageSizeKey = keys.pageSize;
        if (pageIndex == -1 || pageSize == -1) {
            pageIndex = -1;
            pageSize = -1;
        }
        this[pageIndexKey] = pageIndex;
        this[pageSizeKey] = pageSize;
        return;

    }
    /**
       * showdoc
      * @catalog API/工具/Where条件
      *  @title 获取当前where起止页 getPageIndex
       * @description 获取当前where起止页。 
       * @url getPageIndex()
       * @method getPageIndex()
       * @return Int
       * @number 60
       */
    getPageIndex() {
        var pageIndex = this[keys.pageIndex];
        if (pageIndex == -1)
            return 1;
        return pageIndex;
    }
    /**
       * showdoc
      * @catalog API/工具/Where条件
      *  @title 获取当前where页每页显示多少条 getPageSize
       * @description 获取当前where每页显示多少条。 
       * @url getPageSize()
       * @method getPageSize()
       * @return Int
       * @number 60
       */
    getPageSize() {
        var pageSize = this[keys.pageSize];
        if (pageSize == -1)
            return 1000;
        return pageSize;
    }
}
Where.prototype.equal = Where.prototype.eq
export default Where