import dataHelper from "@/base/dataHelper"


//(param, modelFile, funcPath, { request, url, method, pn, extParam })
let query = (param, modelFile, funcPath, options = {}) => {


    let setting = window.HIVUI_SETTING
    if (!options.request && setting)
        options.request = setting.request;
    if (!options.pn && setting)
        options.pn = setting.projectName;
    if (!options.url && setting)
        options.url = setting.queryUrl;
    // //request, url, method, pn, extParam

    return dataHelper.queryHelper.query(param, modelFile, funcPath, options)
}

let exportData = (param, modelFile, funcPath, options = {}) => {
    let setting = window.HIVUI_SETTING
    if (!options.request && setting)
        options.request = setting.request;
    if (!options.pn && setting)
        options.pn = setting.projectName
    if (!options.url && setting)
        options.url = setting.exportUrl;
    //{ request, url, method, pn, extParam }
    return dataHelper.queryHelper.exportData(param, modelFile, funcPath, options)
}
class SaveHelper extends dataHelper.saveHelper {
    constructor(modelFile, funcPath, options = {}) {
        let setting = window.HIVUI_SETTING
        if (!options.request && setting)
            options.request = setting.request;
        if (!options.pn && setting)
            options.pn = setting.projectName;
        if (!options.url && setting)
            options.url = setting.saveUrl;
        //{ request, url, pn, extParam }
        super(modelFile, funcPath, options);
    }
}

export default {
    query,
    exportData,
    Param: dataHelper.queryHelper.Param,
    Where: dataHelper.queryHelper.Where,
    types: dataHelper.queryHelper.types,
    Orders: dataHelper.queryHelper.Orders,
    SaveHelper

}