import Where from "./where"
import Orders from "./orders"
import keys from "./keys"
import utils from '../../../utils'
export default class Param {
    /**
         * showdoc
         * @title 查询参数
         * @className Param
         * @description 查询参数
         * @method Param
         */
    constructor(config) {
        config = config || {};
        this.orders = null
        this.className = 'Params'
        this.paramKey = "queryParam"
        // _.extend(this, config);
        this.where = config.where || new Where();
        this.returnTotal = config.returnTotal;
        this.zcQuery = config.zcQuery;
        this.modelFilePath = config.modelFilePath || "";
        this.orders = config.orders;
        this.slaveEntities =  config.slaveEntities || [];
        if (config.initData) {
            this.setJsonParamsData(config.initData);
        }
        if (config.paramsData) {
            this.setJsonParamsData(config.paramsData);
        }
        if (config.whereData) {
            this.setJsonWhereData(config.whereData);
        }
    }
    createWhereByModel(values, model) {
        model = model || {}
        let where = new Where();
        for (let field in values) {
            let bizFiled = model.fields ? model.fields[field] : null;
            let val = values[field]
            if (val != null && val != "" && val != undefined) {
                if (bizFiled) {
                    where[bizFiled.operate](field, val)
                } else {
                    if (utils.isArray(val))
                        where.in(field, val)
                    else
                        where.def(field, val)
                }
            }
        }
        if (this.where)
            this.where.addConditions(where)
        else this.where = where;
        return where;
    }
    setJsonWhereData(whereData) {
        // 判断传入的值是否字符
        if (!whereData)
            return;
        this.where = new Where({
            criterionData: whereData
        });
    }

    setJsonParamsData(paramsData) {
        // 判断传入的值是否字符
        if (!paramsData)
            return;
        var paramsDataType = typeof (paramsData);
        var objParamsData = {};
        switch (paramsDataType) {
            case "string":
                objParamsData = JSON.parse(paramsData);
                break;
            case "object":
                objParamsData = paramsData;
                break;
        }
        if (objParamsData.queryCdions) {
            this.where = new Where({
                criterionData: objParamsData.queryCdions
            });
        }
        if (objParamsData.orders) {
            this.orders = new Orders({
                orderData: objParamsData.orders
            });
        }
        if (objParamsData.having) {
            this.having = new Where({
                criterionData: objParamsData.having.criterion
            });
        }
        if (objParamsData.groupBy) {
            this.groupBy = objParamsData.groupBy
        }
    }
    /**
      * showdoc
      * @catalog API/工具/Param参数
      * @title 设置分组
      * @description 设置分组
      * @method setGroup
      * @param groupFields 必选 Array 分组字段
      * @return void
      * @number 60
      */
    setGroup(groupFields) {
        this.groupBy = groupFields;
    }
    /**
       * showdoc
       * @title 获取分组
       * @description 获取分组
       * @method getGroup
       * @return Array
       */
    getGroup() {
        return this.groupBy;
    }
    /**
      * showdoc
      * @title 获取where条件
      * @description 获取where条件
      * @method getWhere
      * @return Where
      * @number 60
      */
    getWhere() {
        return this.where;
    }
    /**
      * showdoc
     * @catalog API/工具/Param参数
      * @title 设置where条件
      * @description 设置where条件
      * @method setWhere
      * @param where 必选 where where条件
      * @return void
      * @number 60
      */
    setWhere(where) {
        this.where = where;
    }
   /**
      * showdoc
     * @catalog API/工具/Param参数
      * @title 设置Having条件
      * @description 设置Having条件
      * @method setHaving
      * @param where 必选 where where条件
      * @return void
      * @number 60
      */
    setHaving(where) {
        this.having = where;
    }
   /**
      * showdoc
      * @title 获取Having条件
      * @description 获取Having条件
      * @method getHaving
      * @return void
      * @number 60
      */
    getHaving() {
        return this.having;
    }
    /**
     * showdoc
    * @catalog API/工具/Param参数
     * @title 获取排序条件
     * @description 获取排序条件
     * @method getOrders
     * @return void
     * @number 60
     */
    getOrders() {
        return this.orders;
    }
    /**
      * showdoc
     * @catalog API/工具/Param参数
      * @title 设置排序条件
      * @description 设置排序条件
      * @method setOrders
      * @param orders 必选 order对象
      * @return void
      * @number 60
      */
    setOrders(orders) {
        this.orders = orders;
    }
    /**
      * showdoc
     * @catalog API/工具/Param参数
      * @title 获取查询JSON数据
      * @description 获取查询JSON数据
      * @method toJSON
      * @return json
      * @number 60
      */
    toJSON() {
        var params = {};
        // params.having = {};
        if (this.where) {
            var mycriterion = this.where.toJSON();
            if (mycriterion) {
                params.queryCdions = this.where.toJSON();
            }
        }
        /*
         * // if (this.having) { // var mycriterion = this.having.toJSON(); //
         * if (mycriterion) { // // params.having.criterion =
         * this.having.toJSON(); // } // }
         */
        if (this.groupBy) {
            params.groupBy = this.groupBy;
        }
        if (this.orders) {
            params.orderBy = this.orders.toJSON();
        }
        return params;
    }
    decodeStr(s) {
        return unescape(s.replace(/\\(u[0-9a-fA-F]{4})/gm, '%$1'));
    }
    setSlaveEntities(params){
        this.slaveEntities = params;
    }
    //递归主从SlaveEntities条件，主从从
    toRecursiveSlaveEntitiesWithKey(slaveEntitiesItem){
        var json = {}, _this =slaveEntitiesItem;
        // param[this.paramKey] = this.toJSON()
        let body = _this.toJSON();
        json[keys.body] = body;
        json[keys.pageIndex] = _this[keys.pageIndex] || _this.where[keys.pageIndex];
        json[keys.pageSize] = _this[keys.pageSize] || _this.where[keys.pageSize];
        json[keys.returnTotal] = _this.returnTotal;
        json[keys.modelFilePath] = _this.modelFilePath;
        json[keys.zcQuery] = _this.zcQuery;
        if(_this.slaveEntities.length>0)
        {
            var _slaveEntities = [];
            for(var i = 0;i<_this.slaveEntities.length;i++)
            {
                _slaveEntities.push(this.toRecursiveSlaveEntitiesWithKey(_this.slaveEntities[i]));
            }
            json[keys.slaveEntities] = _slaveEntities; //JSON.stringify(_slaveEntities);
        }
        return json
    }
    toStringWithKey() {
        var json = {};
        // param[this.paramKey] = this.toJSON()
        let body = JSON.stringify(this.toJSON());
        json[keys.body] = body;
        json[keys.pageIndex] = this[keys.pageIndex] || this.where[keys.pageIndex];
        json[keys.pageSize] = this[keys.pageSize] || this.where[keys.pageSize];
        json[keys.returnTotal] = this.returnTotal;
        json[keys.modelFilePath] = this.modelFilePath;
        json[keys.zcQuery] = this.zcQuery;
        if(this.slaveEntities.length>0)
        {
            var _slaveEntities = [];
            for(var i = 0;i<this.slaveEntities.length;i++)
            {
                _slaveEntities.push(this.toRecursiveSlaveEntitiesWithKey(this.slaveEntities[i]));
            }
            json[keys.slaveEntities] = JSON.stringify(_slaveEntities); //JSON.stringify(_slaveEntities);
        }
            
        return json;// this.toJSON();
    }
    /**
      * showdoc
     * @catalog API/工具/Param参数
      * @title 获取查询字符串数据
      * @description 获取查询字符串数据
      * @method toStr
      * @return String
      * @number 60
      */
    toStr() {
        return JSON.stringify(this.toJSON());
    }
    /**
       * showdoc
       * @title 是否有翻页条件
       * @description 是否有翻页条件
       * @method isPaging
       * @return boolean
       * @number 60
       */
    isPaging() {
        var pageIndexKey = keys.pageIndex;
        var pageSizeKey = keys.pageSize;

        if (_has(this, pageIndexKey) && _has(this, pageSizeKey))
            return true;
        else
            return false;
    }
    /**
      * showdoc
     *  @title 设置翻页
      * @description 设置翻页
      * @method setPage
      * @param pageIndex 必选 Int 起止页
      * @param pageSize 必选 Int 每页显示多少条
      * @return void
      * @number 60
      */
    setPage(pageIndex, pageSize) {
        var pageIndexKey = keys.pageIndex;
        var pageSizeKey = keys.pageSize;
        if (pageIndex == -1 || pageSize == -1) {
            pageIndex = -1;
            pageSize = -1;
        }
        this[pageIndexKey] = pageIndex;
        this[pageSizeKey] = pageSize;
        return;

    }
    /**
       * showdoc
      * @catalog API/工具/Where条件
      *  @title 获取当前where起始页
       * @description 获取当前where起始页
       * @method getPageIndex
       * @return Int
       * @number 60
       */
    getPageIndex() {
        var pageIndex = this[keys.pageIndex];
        if (pageIndex == -1)
            return 1;
        return pageIndex;
    }
    /**
       * showdoc
      * @catalog API/工具/Where条件
      *  @title 获取当前where页每页显示多少条
       * @description 获取当前where每页显示多少条
       * @method getPageSize
       * @return Int
       * @number 60
       */
    getPageSize() {
        var pageSize = this[keys.pageSize];
        if (pageSize == -1)
            return 1000;
        return pageSize;
    }
}