const bom = {
    getUrlParam(name) {
        let search = window.location.search;
        search = search.substring(1, search.length)
        let values = search.split("&");
        let result = {};
        for (let i = 0; i < values.length; i++) {
            const element = values[i];
            let _val = element.split("=");
            result[_val[0]] = _val[1];
        }
        if (name)
            return result[name];
        return result;
    },
    //打开链接
    openUrl(url, paramters={}, method='get', target = "_blank") {
        //创建form表单
        let id = "formid" + (new Date()).valueOf();

        if (!url.toLowerCase().startsWith("http") && window.eap) {
            url = window.eap.utils.biz.getUrl(url);
        }

        var formredwin = document.createElement("form");
        formredwin.method = method || "get";
        document.body.appendChild(formredwin);
        formredwin.target = target;
        formredwin.action = url
        for (var item in paramters) {
            if(url.indexOf(item+"=")>-1)
                continue;
            var inputObj = document.getElementById(`${id}_${item}`);
            if (inputObj) {
                inputObj.value = paramters[item];
            } else {
                var opt = document.createElement("input");
                opt.type = "hidden";
                opt.name = item;
                opt.value = paramters[item];
                formredwin.appendChild(opt);
            }
        }


        formredwin.submit();
        formredwin.parentNode.removeChild(formredwin);
    },
    mergeDefaultCfg(defaultCmp, controlCfg) {
        var result = null;
        var tempdefaultCmp = defaultCmp;
        for (var key in controlCfg) {
            if ((typeof controlCfg[key]) == "object") {
                controlCfg[key] = bom.recursionCfg(tempdefaultCmp, controlCfg[key]);
            }
            if (key == "crlName" && tempdefaultCmp[controlCfg["crlName"]] != undefined) {
                controlCfg = Object.assign({}, tempdefaultCmp[controlCfg["crlName"]], controlCfg);
            }
        }
        return controlCfg;
    },

    recursionCfg(defaultCmp, recuCfg) {
        var tempdefaultCmp = defaultCmp;
        var result = null;
        for (var key in recuCfg) {
            if ((typeof recuCfg[key]) == "object") {
                recuCfg[key] = bom.recursionCfg(tempdefaultCmp, recuCfg[key]);
            }
            if (key == "crlName" && tempdefaultCmp[recuCfg["crlName"]] != undefined) {
                recuCfg = Object.assign({}, tempdefaultCmp[recuCfg["crlName"]], recuCfg);
            }
        }
        return recuCfg;
    }
}
export default bom