 
import _date from "./src/date"
import _number from "./src/number"
import _string from "./src/string"
import _dbType from "./src/dbType"
import _bom from "./src/bom"
export let date=_date;
export let number=_number;
export let string=_string;
export let dbType = _dbType;
export let bom = _bom;

export default {
    date,number,string,dbType,bom
};




export let DateUtil=_date;
export let NumberUtil=_number;
export let StringUtil=_string;
export let DbTypeUtil = _dbType;
export let BomUtil = _bom;
 