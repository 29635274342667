
import request from "./request"
import { ajax } from "./axios"
import hiSetting from "@/hiSetting.js";
import user from "./user"
import utils from "./utils"
import page from "./page"
import dataHelper from "./dataHelper"
import defaultsDeep from "lodash/defaultsDeep";
function mergeConfig() {
    let args = [...arguments]
    return defaultsDeep(...args)
}
let lang = {
    get(key) {
        //return Vue.prototype.$t(key)
        let keys = {}
        if (window.lang && window.lang.keys)
            keys = window.lang.keys;
        return keys[key] || key;
    }
}

export { user, ajax, request, mergeConfig, lang, utils, page, dataHelper, };
export default {
    user,ajax,
    request,
    mergeConfig,
    lang,
    utils,
    page,
    dataHelper,
};
//加载默认设至到全局
if (!window.HIVUI_SETTING) {
    window.HIVUI_SETTING = hiSetting;
    //console.log("已加载默认配置:", hiSetting)
}
window.lang = lang
//输出版本信息
import Package from '../../package_eap.json'
console.log(
    '%c hi-eap-basic  %c v'.concat(Package.version, ' '),
    'padding: 2px 1px; border-radius: 3px 0 0 3px; color: #fff; background: #ff8e15; font-weight: bold;',
    'padding: 2px 1px; border-radius: 0 3px 3px 0; color: #fff; background: #42c02e; font-weight: bold;',
);

//console.log(process.env.NODE_VER)