
export default class Orders {
/**
   * showdoc
   * @title 查询排序
   * @className Orders
   * @method Orders
   */
    constructor(config) {
        config = config || {};
        this.orderData = [];
        if (config.orderData) {
            this.orderData = config.orderData;
        }
    }
    getOrderItemModel() {
        return {
            name: "",
            desc: "",
            type: "desc"
        };
    }
    /**
      * showdoc
      * @title 添加降序条件
      * @description 添加降序条件
      * @method addDesc
      * @param property 必选 String 字段名
      * @param desc 可选 String 字段描述
      * @return void
      * @number 1
      */
    addDesc(property, desc) {
        this.addOrder(property, "desc", desc);
    }
     /**
      * showdoc
      * @catalog API/工具/Orders排序
      * @title 添加升序
      * @description 添加升序条件
      * @method addAsc
      * @param property 必选 String 字段名
      * @param desc 可选 String 字段描述
      * @return void
      */
    addAsc(property, desc) {
        this.addOrder(property, "asc", desc);
    }
    /**
      * showdoc
      * @title 添加排序
      * @description 添加排序
      * @method addOrder
      * @param property 必选 String 字段名
      * @param type 必选 String 排序类型
      * @param desc 可选 String 字段描述
      * @return void
      * @number 1
      */
    addOrder(property, type, desc) {
        var orderItem = this.getOrderItemModel();
        orderItem.name = property;
        orderItem.desc = desc;
        orderItem.type = type
        this.orderData.push(orderItem);
    }
      /**
       * showdoc
       * @title 获取order排序JSON数据
       * @description 获取order排序JSON数据
       * @method toJSON
       * @return json
       * @number 60
       */
    toJSON() {
        return this.orderData;
    }
      /**
       * showdoc
       * @title 获取order排序字符串数据
       * @description 获取order排序字符串数据
       * @method toStr
       * @return json
       * @number 60
       */
    toStr() {
        return JSON.stringify(this.orderData);
    }

}