import number from './number'
import date from './date'
import { t } from '@/locale/global.js'
let _this = {
    dbString: {
        format: function (v, format) {
            if (typeof (format) == "function") {
                return format.call(window, v);
            }
            if (!format)
                return v;
            return v;
        },
        formatView: function (v, format) {
            if (typeof (format) == "function") {
                return format.call(window, v);
            }
            if (!format)
                return v;
            return v;
        },
        type: "dbString",
        formType: {
            element: "el-input"
        }
    },
    dbInt: {
        isNumber:true,
        format: function (v, format) {
            v = parseInt(v);
            if (format == "eleAuditPoint") {
                let val = ""
                switch (v) {
                    case 0:
                        val = t("hi_dbType_AuditPoint_0")
                        break;
                    case 1:
                        val = t("hi_dbType_AuditPoint_1")
                        break;
                    case 99:
                        val = t("hi_dbType_AuditPoint_99")
                        break;
                    case 2:
                        val = t("hi_dbType_AuditPoint_2")
                        break;
                    default:
                        val = isNaN(v) ? "" : v
                }
                return val;
            }
            if (typeof (format) == "function") {
                return format.call(window, v);
            }
            if (isNaN(v)) {
                return "";
            }
            v = v * 1;
            if (!format)
                return v;
            return number.format(v, format);
        },
        formatView: function (v, format) {
            if (typeof (format) == "function") {
                return format.call(window, v);
            }
            let val = _this.dbInt.format(v, format);
            if (format && format.indexOf('%') > 0) {
                if (v > 0) {
                    return '<font color="red">' + val + '</font>';
                } else {
                    return '<font color="green">' + val + '</font>';
                }
            }
            return val;
        },
        // 数值型默认值不设置
        defautlVal: 0,
        type: "dbInt",
        queryType: {
            element: "HiNumberRange"
        },
        formType: {
            element: "hi-number",
            props: {
                precision: 0,
                "controls-position": "right"
            }
        }

    },
    dbFloat: {
        isNumber:true,
        format: function (v, format) {
            if (typeof (format) == "function") {
                return format.call(window, v);
            }
            v = parseFloat(v);
            if (isNaN(v)) {
                return "";
            }
            v = v * 1.0;
            if (!format)
                return v;
            return number.format(v, format);
        },
        formatView: function (v, format) {
            if (typeof (format) == "function") {
                return format.call(window, v);
            }
            var val = _this.dbFloat.format(v, format);
            if (format && format.indexOf('%') > 0) {
                if (v > 0) {
                    return '<font color="red">' + val + '</font>';
                } else {
                    return '<font color="green">' + val + '</font>';
                }
            }
            return val;
        },
        type: "dbFloat",
        formType: {
            element: "hi-number",
            props: {
                "controls-position": "right"
            }
        },
        queryType: {
            element: "HiNumberRange"
        },
        defautlVal: 0
    },
    dbDouble: {
        isNumber:true,
        format: function (v, format) {
            if (typeof (format) == "function") {
                return format.call(window, v);
            }
            v = parseFloat(v);
            if (isNaN(v)) {
                return "";
            }
            v = v * 1.0;
            if (!format)
                return v;
            return number.format(v, format);
        },
        formatView: function (v, format) {
            if (typeof (format) == "function") {
                return format.call(window, v);
            }
            var val = _this.dbDouble.format(v, format);
            if (format && format.indexOf('%') > 0) {
                if (v > 0) {
                    return '<font color="red">' + val + '</font>';
                } else {
                    return '<font color="green">' + val + '</font>';
                }
            }
            return val;
        },
        type: "dbDouble",
        formType: {
            element: "hi-number",
            props: {
                "controls-position": "right"
            }
        },
        queryType: {
            element: "HiNumberRange"
        },
        defautlVal: 0
    },
    dbText: {
        format: function (v, format) {
            if (typeof (format) == "function") {
                return format.call(window, v);
            }
            if (!format)
                return v;
            return v;
        },
        formatView: function (v, format) {
            if (!format)
                return v;
            return v;
        },
        type: "dbText",
        formType: {
            element: "el-input",
            props: {
                type: "textarea",
                rows: 5
            }
        },
        queryType: {
            element: "el-input",
            props: {

            }
        },
        gridType: {
            element: "hi-textarea",
            props: {
                popup: true,
                rows: 5
            }
        }
    },
    dbBoolean: {
        format: function (v, format) {
            if (typeof (format) == "function") {
                return format.call(window, v);
            }
            if (v === "") {
                return "";
            }
            if (typeof v != "boolean") {
                if (v * 1 == v) {
                    v = parseInt(v);
                }
            }

            return (typeof v == "boolean" || typeof v == "number") ? (v
                ? t("hi_dbType_boolean_yes")
                : t("hi_dbType_boolean_no")) : v;
        },
        formatView: function (v, format) {
            return _this.dbBoolean.format(v, format);
        },
        parse: function (v) {
            if (v == "yes" || v == "是" || v == "1" || v == "true"
                || v == "y")
                return true;
            else
                return false;
        },
        type: "dbBoolean",
        queryType: {
            element: "el-checkbox",
            props: {
            }
        },
        formType: {
            element: "el-checkbox",
            props: {
            }
        }

    },
    dbDatetime: {
        format: function (v, format) {
            if (typeof (format) == "function") {
                return format.call(window, v);
            }
            if (v == null || v == "")
                return v;
            format = format || "yyyy-MM-dd hh:mm:ss";

            if (typeof v == "string")
                v = date.strToDate(v);
            return date.format(v, format)
        },

        formatView: function (v, format) {
            return _this.dbDatetime.format(v, format);
        },
        /**
         * @property datetime.type
         * @description datetime类型
         * @type {String}
         * @final
         * @static
         */
        type: "dbDatetime",
        queryType: {
            element: "HiTimeScheme"
        },
        formType: {
            element: "el-date-picker",
            props: {
                type: "datetime"
            }

        }
    },

    dbTimestamp: {
        format: function (v, format) {
            if (typeof (format) == "function") {
                return format.call(window, v);
            }
            if (v == null || v == "")
                return v;
            format = format || "yyyy-MM-dd hh:mm:ss";

            if (typeof v == "string")
                v = date.strToDate(v);
            return date.format(v, format)
        },
        formatView: function (v, format) {
            return _this.dbDatetime.format(v, format);
        },
        type: "dbTimestamp",
        formType: {
            element: "el-date-picker",
            props: {
                type: "datetime"
            }
        }
    },
    dbBasic: {
        type: "dbBasic"
    },
    dbArray: {
        type: "dbArray"
    },
    dbObject: {
        type: "dbObject"
    },
    dbList: {
        type: "dbList"
    }
}
_this.dbDate = _this.dbDatetime;

export default _this;