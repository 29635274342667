export default {
    closepage() {
        if (top.window.SysPage && top.window.SysPage.closePage) {
            top.window.SysPage.closePage();
        } else {
            window.close();
        }

    },
    /*
        title:标签页名称，不传则默认显示功能名称
        url:标签页访问地址
        params:携带参数
        method:传"get"/"post"参数，不传默认为get
        target:传"me"/"_self"/"_blank"，me为当前浏览器标签页覆盖打开，_self为强制当前框架标签页打开，_blank为强制框架新标签打开，不传参数则默认同url标签覆盖，不同的url打开新标签
    */
    newPage(title,url,params,method,target){
        if(url&&url.indexOf("http")!=0){
            url=location.origin+(top.window.deployDir?("/"+top.window.deployDir):'') + (top.window.pName?("/"+top.window.pName):'') + url;
        }
        if (top.window.SysPage && top.window.SysPage.newPage) {
            top.window.SysPage.newPage(title,url,params,method,target);
        } else {
            window.open(url,"_blank");
        }
    }
}