import Where from "./where"
import Param from "./param"
import types from "./types"
import Orders from "./orders"
import isArray from "lodash/isArray"
import isDate from "lodash/isDate"
import { date } from "../../../utils/"
import keys from "./keys"


//行转列
let rowToCol = (data, rowField, colField, valField) => {
    let result = [], row = {};
    if (data.length > 0) {
        let rowSign = row[rowField] = data[0][rowField];
        data.forEach(element => {
            //标识不一样就创建行
            if (rowSign != element[rowField]) {
                result.push(row);
                row = {};
                rowSign = element[rowField]
                row[rowField] = rowSign;
            }
            row[element[colField]] = element[valField]
        });
        result.push(row);
    }
    return result;
}

let query = (param, modelFile, funcPath, { request, url, method, pn, extParam, viewItemId, rowToCol,async,success,fail}) => {
    let data = {
        "queryCdion": "{}"
    }

    if (param) {
        let paramInstance
        if (param.className == "Where") {
            paramInstance = new Param(); //new queryParam.Param()
            paramInstance.where = param
        }
        else if (param.className == "Params") {
            paramInstance = param
        } else {
            paramInstance = new Param();
            let pageSize = null, pageIndex = null;
            let where = new Where();
            for (let field in param) {
                let val = param[field]
                if (field == '$pageSize') {
                    pageSize = val;
                    continue;
                }
                if (field == '$pageIndex') {
                    pageIndex = val;
                    continue;
                }
                if (isArray(val)) {
                    if (isDate(val[0])) {
                        where.between(field, date.format(val[0], "yyyy-MM-dd hh:mm:ss") + '到' + date.format(val[1], "yyyy-MM-dd hh:mm:ss"))
                    } else
                        where.in(field, val)
                }
                else
                    where.def(field, val)

            }
            if (pageSize !== null && pageIndex !== null)
                where.setPage(pageIndex, pageSize)
            paramInstance.where = where
        }
        data = paramInstance.toStringWithKey()
    }
    data.modelFilePath = modelFile;
    data[keys.funcpath] = funcPath;
    data[keys.viewItemId] = viewItemId;
    //将来要移到url中
    //data.funcPath = funcPath;

    if (extParam) {
        Object.assign(data, extParam);
    }
    let promise
    //let dataUrl = url + "/" + modelFile;
    let dataUrl = url;
    funcPath = funcPath || ""

    if (pn) {
        if (dataUrl.indexOf('?') == -1)
            dataUrl = dataUrl + "?pn=" + pn;
        else
            dataUrl = dataUrl + "&pn=" + pn;
    }

    promise = request({
        url: dataUrl,
        method: method || 'post',
        async:async,
        data: data,
        success:success,
        fail:fail
    })
    // promise.then(res => {
    //     //行转列
    //     if (rowToCol && rowToCol.rowField) {
    //         res.dataPack.row = rowToCol(res.dataPack.row)
    //     }
    // })

    return promise
}
let getExportParam = ({ request, url, csv }) => {
    let params = {
        csv
    }
    return request({
        url,
        method: 'get',
        params
    })
}
let exportData = (param, modelFile, funcPath, { request, url, method, pn, viewItemId, extParam }) => {
    let data = {
        // "queryCdion": "{}"
    }

    if (param) {
        let paramInstance
        if (param.className == "Where") {
            paramInstance = new Param(); //new queryParam.Param()
            paramInstance.where = param
        }
        else if (param.className == "Params") {
            paramInstance = param
        } else {
            paramInstance = new Param();
            let pageSize = null, pageIndex = null;
            let where = new Where();
            for (let field in param) {
                let val = param[field]
                if (field == '$pageSize') {
                    pageSize = val;
                    continue;
                }
                if (field == '$pageIndex') {
                    pageIndex = val;
                    continue;
                }
                if (isArray(val)) {
                    if (isDate(val[0])) {
                        where.between(field, date.format(val[0], "yyyy-MM-dd hh:mm:ss") + '到' + date.format(val[1], "yyyy-MM-dd hh:mm:ss"))
                    } else
                        where.in(field, val)
                }
                else
                    where.def(field, val)

            }
            if (pageSize !== null && pageIndex !== null)
                where.setPage(pageIndex, pageSize)
            paramInstance.where = where
        }

        data[keys.body] = paramInstance.toJSON()//paramInstance.toStringWithKey()
        data[keys.pageIndex] = paramInstance.where[keys.pageIndex];
        data[keys.pageSize] = paramInstance.where[keys.pageSize];
        data[keys.viewItemId] = viewItemId;
        //delete data[keys.zcQuery]
        //delete data[keys.returnTotal]
    }
    data[keys.funcpath] = funcPath;
    data.modelFilePath = modelFile;
    // if (param.zcQuery)
    //     data[keys.slaveExport] = param.zcQuery

    //将来要移到url中
    //data.funcPath = funcPath;

    if (extParam) {
        if (extParam.sheetStyle) {
            data[keys.sheetStyle] = extParam.sheetStyle;
            delete extParam.sheetStyle
        }
        if (extParam[keys.slaveExport] !== undefined) {
            data[keys.slaveExport] = extParam[keys.slaveExport];
            delete extParam[keys.slaveExport]
        }

    }
    if (data[keys.slaveExport] == undefined && param.zcQuery)
        data[keys.slaveExport] = param.zcQuery
    let promise
    //let dataUrl = url + "/" + modelFile;
    let dataUrl;
    funcPath = funcPath || ""
    dataUrl = url;

    if (pn) {
        if (dataUrl.indexOf('?') == -1)
            dataUrl = dataUrl + "?pn=" + pn;
        else
            dataUrl = dataUrl + "&pn=" + pn;
    }
    let psotData = {}
    if (data.title) {
        psotData.title = data.title;
        delete data.title;
    }
    psotData[keys.sheetDatas] = JSON.stringify([data])
    psotData.__isIntercept = false;
    if (extParam)
        psotData = Object.assign(psotData, extParam);
    promise = request({
        url: dataUrl,
        method: method || 'post',
        data: psotData
    })

    return promise
}

export default {
    Param,
    Where,
    types,
    Orders,
    query,
    exportData,
    rowToCol,
    getExportParam
}
