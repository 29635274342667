/**
   * showdoc
   * @catalog API/工具/Number
   * @title 数值基础类
   * @className NumberFunc
   * @modifier static
   * @method NumberFunc
   * @demo 
   */
const NUM = {

	/**
	   * showdoc
	  * @catalog API/工具/Number
	   * @title 除法函数，用来得到精确的除法结果
	   * @description 除法函数，用来得到精确的除法结果
	   * @url accDiv(arg1,arg2)
	   * @method accDiv
	   * @param arg1 必选 Number 被除数
	   * @param arg2 必选 Number 除数
	   * @return float
	   * @number 60  
	   */
	accDiv: function (arg1, arg2) {
		var t1 = 0, t2 = 0, r1, r2;
		try {

			t1 = arg1.toString().split(".")[1].length
		} catch (e) {
			t1 = 0;
		}
		try {
			t2 = arg2.toString().split(".")[1].length
		} catch (e) {
			t2 = 0;
		}
		r1 = Number(arg1.toString().replace(".", ""))
		r2 = Number(arg2.toString().replace(".", ""))
		return (r1 / r2) * Math.pow(10, t2 - t1);
	},

	/**
	   * showdoc
	  * @catalog API/工具/Number
	   * @title 乘法函数，用来得到精确的乘法结果
	   * @description 乘法函数，用来得到精确的乘法结果
	   * @url accDiv(arg1,arg2)
	   * @method accDiv
	   * @param arg1 必选 Number 乘数
	   * @param arg2 必选 Number 乘数
	   * @return float
	   * @number 60  
	   */
	accMul: function (arg1, arg2) {
		var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
		try {
			m += s1.split(".")[1].length
		} catch (e) {
			m = 0;
		}
		try {
			m += s2.split(".")[1].length
		} catch (e) {
			m += 0;
		}
		return Number(s1.replace(".", "")) * Number(s2.replace(".", ""))
			/ Math.pow(10, m)
	},
	/**
		   * showdoc
		  * @catalog API/工具/Number
		   * @title 加法函数，用来得到精确的加法结果
		   * @description 加法函数，用来得到精确的加法结果
		   * @url accAdd(arg1,arg2)
		   * @method accAdd
		   * @param arg1 必选 Number 加数
		   * @param arg2 必选 Number 加数
		   * @return float
		   * @number 60  
		   */

	accAdd: function (arg1, arg2) {
		var r1, r2, m;
		try {
			r1 = arg1.toString().split(".")[1].length
		} catch (e) {
			r1 = 0
		}
		try {
			r2 = arg2.toString().split(".")[1].length
		} catch (e) {
			r2 = 0
		}
		m = Math.pow(10, Math.max(r1, r2))
		return (NUM.accMul(arg1, m) + NUM.accMul(arg2, m)) / m
	},

	toFixed(number, length) {
		var carry = 0; //存放进位标志
		var num, multiple; //num为原浮点数放大multiple倍后的数，multiple为10的length次方
		var str = number + ''; //将调用该方法的数字转为字符串
		var dot = str.indexOf("."); //找到小数点的位置
		if (str.substr(dot + length + 1, 1) >= 5) carry = 1; //找到要进行舍入的数的位置，手动判断是否大于等于5，满足条件进位标志置为1
		multiple = Math.pow(10, length); //设置浮点数要扩大的倍数
		num = Math.floor(number * multiple) + carry; //去掉舍入位后的所有数，然后加上我们的手动进位数
		var result = num / multiple + ''; //将进位后的整数再缩小为原浮点数
		/*
		* 处理进位后无小数
		*/
		dot = result.indexOf(".");
		if (dot < 0) {
			result += '.';
			dot = result.indexOf(".");
		}
		/*
		* 处理多次进位
		*/
		var len = result.length - (dot + 1);
		if (len < length) {
			for (var i = 0; i < length - len; i++) {
				result += 0;
			}
		}
		return result;

	},
	/**
	   * showdoc
	  * @catalog API/工具/Number
	   * @title 中文数字格式化
	   * @description 中文数字格式化
	   * @url formatCn(n)
	   * @method formatCn
	   * @param n 必选 Number 数字
	   * @return string
	   * @number 60  
	   */
	formatCn(n) {
		let fraction = ['角', '分'];
		let digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
		let unit = [['元', '万', '亿'], ['', '拾', '佰', '仟']];
		let head = n < 0 ? '欠' : '';
		n = Math.abs(n);

		let s = '';

		for (let i = 0; i < fraction.length; i++) {
			s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
		}
		s = s || '整';
		n = Math.floor(n);

		for (let i = 0; i < unit[0].length && n > 0; i++) {
			let p = '';
			for (let j = 0; j < unit[1].length && n > 0; j++) {
				p = digit[n % 10] + unit[1][j] + p;
				n = Math.floor(n / 10);
			}
			s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
		}
		return head + s.replace(/(零.)*零元/, '元').replace(/(零.)+/g, '零').replace(/^整$/, '零元整');
	},

	/**
	   * showdoc
	  * @catalog API/工具/Number
	   * @title 数值格式化
	   * @description 数值格式化
	   * @url formatString(v,formatstring)
	   * @method formatString
	   * @param v 必选 Number 数字
	   * @param strign 必选 string 格式化
	   * @return string
	   * @number 60  
	   */
	format: function (v, formatString) {
		if (!formatString) {
			formatString = "000,0.00";
			//return v;
		}

		if (formatString == "￥") {
			formatString = "￥000,0.00";
		}
		if (isFinite(v)) {
			v = parseFloat(v);
		}
		if (formatString.indexOf('%') > -1) {
			v = v * 100;
		}
		v = !isNaN(v) ? v : NaN;
		if (isNaN(v)) {
			return '';
		}
		let formatCleanRe = /[^\d\.]/g;
		let comma = ",", dec = '.', neg = v < 0, hasComma, psplit, fnum, cnum, parr, j, m, n, i;
		v = Math.abs(v);
		// if (formatString.substr(formatString.length - 2) == '/i') {
		// 	I18NFormatCleanRe = new RegExp('[^\\d\\'
		// 		+ UtilFormat.decimalSeparator + ']', 'g');
		// 	formatString = formatString.substr(0, formatString.length - 2);
		// 	hasComma = formatString.indexOf(comma) != -1;
		// 	psplit = formatString.replace(I18NFormatCleanRe, '').split(dec);
		// } else

		hasComma = formatString.indexOf(',') != -1;
		psplit = formatString.replace(formatCleanRe, '').split('.');

		if (psplit.length > 2) {
			alert("对比格式无效")
			// alert("瀵规瘮璧锋棤鏁堟牸寮忥紒");
			// </debug>
		} else if (psplit.length > 1) {

			let precision = psplit[1].length || 0;
			let pow = Math.pow(10, precision);
			v = Number((Math.round(v * pow) / pow)).toFixed(precision);

		} else {
			let precision = psplit[0].length || 0;
			let pow = Math.pow(10, precision);
			v = Number((Math.round(v * pow) / pow)).toFixed(0);
		}

		fnum = v.toString();

		psplit = fnum.split('.');

		if (hasComma) {
			cnum = psplit[0];
			parr = [];
			j = cnum.length;
			m = Math.floor(j / 3);
			n = cnum.length % 3 || 3;

			for (i = 0; i < j; i += n) {
				if (i !== 0) {
					n = 3;
				}

				parr[parr.length] = cnum.substr(i, n);
				m -= 1;
			}
			fnum = parr.join(comma);
			if (psplit[1]) {
				fnum += dec + psplit[1];
			}
		} else {
			if (psplit[1]) {
				fnum = psplit[0] + dec + psplit[1];
			}
		}

		if (neg) {
			neg = fnum.replace(/[^1-9]/g, '') !== '';
		}
		return (neg ? '-' : '') + formatString.replace(/[\d,?\.?]+/, fnum);
	}

}

export default NUM