// 打开功能接口
let getFuncInfo = ({ request, url, data }) => {
    let promise;
    promise = request({
        url: url,
        data: data,
        method: 'post'
    })
    return promise
}

export default {
    getFuncInfo
}
