import Cookies from 'js-cookie'

const TokenKey = 'EAP-Token'
import tokenId from "@/utils/token.js"
export function getToken() {
  let token = Cookies.get(TokenKey)
  let env = process.env;
  if (!token && env.NODE_ENV == "development") {
    return tokenId;
  }
  return token;
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}


export default {
  getToken,
  setToken,
  removeToken,
}